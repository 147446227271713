import React, { useEffect, useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import Layout from "../components/Layout";

interface Question {
  questionText: string;
}

export interface QuestionForm {
  questions: Question[];
}

const Contact = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isAble, setIsAble] = useState(false);
  const [formrunApi, setFormrunApi] = useState("");

  const {
    register,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    const dir = location.pathname.slice(0, 3);
    if (dir == "/ja") {
      setFormrunApi("8ezj035c0j3mtvksojb5dqzt");
    } else {
      setFormrunApi("mmd7snxhk4drzqyknm5pux97");
    }
  }, []);

  useEffect(() => {
    const mailError = document.getElementById("mailError") as HTMLSpanElement;
    const formInput = document.querySelectorAll<HTMLInputElement>(".valid");

    for (let i = 0; i < formInput.length; i++) {
      formInput[i].addEventListener("change", () => {
        if (
          formInput[0].value !== "" &&
          formInput[1].value !== "" &&
          formInput[2].value !== "" &&
          formInput[3].checked &&
          mailError == null
        ) {
          setIsAble(true);
        } else {
          setIsAble(false);
        }
      });
    }
  }, []);

  const title = t("siteName");

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
      </Helmet>
      <Layout>
        <main className="pt-100px pb-163px md:pt-160px md:pb-230px">
          <div className="mx-auto w-325 md:w-760">
            <h1 className="mb-90px text-center font-condensed text-64px tracking-04 md:mb-40px md:text-128px">
              Contact
            </h1>
            <p className="mb-30px flex justify-center text-16px font-medium leading-1.8 md:mb-120px md:text-24px">
              {t("contactDesc")}
            </p>
            <div className="mx-auto md:w-560">
              <form
                action={`https://form.run/api/v1/r/${formrunApi}`}
                method="post"
                className="mx-auto w-300px md:w-full"
              >
                <div className="mb-38px">
                  <label className="flex items-center text-13px font-medium" htmlFor="name">
                    {t("headName")}
                    <span className="form-label">{t("requiredLabel")}</span>
                  </label>
                  <input
                    className="form-input valid py-10px"
                    id="name"
                    type="text"
                    {...register("name", { required: `${t("errorRequired")}` })}
                  />
                  {errors.name && (
                    <span className="mt-10px ml-16px block text-10px font-medium text-fb">{t("errorRequired")}</span>
                  )}
                </div>
                <div className="mb-38px">
                  <label className="mb-9px flex items-center text-13px font-medium" htmlFor="email">
                    {t("headMail")}
                    <span className="form-label">{t("requiredLabel")}</span>
                  </label>
                  <input
                    className="form-input valid py-10px"
                    id="email"
                    type="text"
                    {...register("email", {
                      required: `${t("errorRequired")}`,
                      pattern: {
                        value: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
                        message: `${t("errorMail")}`,
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="mt-10px ml-16px block text-10px font-medium text-fb" id="mailError">
                      {t("errorMail")}
                    </span>
                  )}
                </div>
                <div className="mb-40px md:mb-60px">
                  <label className="mb-9px flex items-center text-13px font-medium" htmlFor="content">
                    {t("headContent")}
                    <span className="form-label">{t("requiredLabel")}</span>
                  </label>
                  <textarea
                    className="form-input valid mt-15px h-200px rounded border p-10px leading-1.8"
                    id="content"
                    {...register("content", { required: `${t("errorRequired")}` })}
                  ></textarea>
                  {errors.content && (
                    <span className="mt-10px ml-16px block text-10px font-medium text-fb">{t("errorRequired")}</span>
                  )}
                </div>
                <div className="mb-60px">
                  <div className="flex items-start md:items-center">
                    <input className="valid" type="checkbox" id="agree" />
                    <label
                      className="mt-[-7px] text-14px font-medium leading-1.8 md:mt-[-2px] md:leading-none"
                      htmlFor="agree"
                    >
                      {t("agree")}
                    </label>
                  </div>
                </div>
                {/* ボット投稿をブロックするためのタグ */}
                <div className="_formrun_gotcha hidden">
                  {/* <style media="screen">._formrun_gotcha {position:absolute!important;height:1px;width:1px;overflow:hidden;}</style> */}
                  <label htmlFor="_formrun_gotcha">If you are a human, ignore this field</label>
                  <input type="text" name="_formrun_gotcha" id="_formrun_gotcha" tabIndex={-1} />
                </div>
                <button
                  className={clsx(
                    "h-67px w-full rounded-contact-btn text-center text-24px font-medium",
                    isAble
                      ? "pointer-events-auto bg-navy text-white"
                      : "pointer-events-none border border-solid text-gray"
                  )}
                  type="submit"
                >
                  <span>{t("submit")}</span>
                </button>
              </form>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default Contact;

export const query = graphql`
  query Contact($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
